import * as React from "react"
import { Link } from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import Button from "../Button/Button"

import * as styles from "./Hero.module.scss"

const Hero = () => {
  return (
    <Carousel className={styles.carousel}>
      <Carousel.Item className={styles.item}>
        <Carousel.Caption className={styles.caption}>
          <h3>24/7 Access</h3>

          <p>
            Having an important call at 4am? No problem, we got you covered! All
            our members get an access card to enter any time they want. It's not
            a coworking space, it's your office!
          </p>

          <Link to="#contact">
            <Button variant="primary" className="mt-3">
              Get Access
            </Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className={styles.item}>
        <Carousel.Caption className={styles.caption}>
          <h3>Meeting Room</h3>

          <p>
            Take a call or invite clients over with privacy and comfort. Our
            meeting room is the perfect place for your next business
            conversation.
          </p>

          <Link to="#contact">
            <Button variant="primary" className="mt-3">
              Get Access
            </Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className={styles.item}>
        <Carousel.Caption className={styles.caption}>
          <h3>Dedicated Desk</h3>

          <p>
            At Bukithub, we offer dedicated desks at weekly, monthly or yearly
            plans. Every office floor has 10 tables with wide spacing
            in-between. Fast WiFi, Power Outlets & USB, office chairs and full
            AC.
          </p>

          <Link to="#contact">
            <Button variant="primary" className="mt-3">
              Get Access
            </Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>

      {/* <Carousel.Item className={styles.item}>
        <Carousel.Caption className={styles.caption}>
          <h3>Kitchen</h3>

          <p>
            Our fully equiped kitchen can serve you for lunch or a small snack
            in-between with a big fridge, microwave & oven. Free coffee, tea and
            water are self explainable.
          </p>

          <Link to="#contact">
            <Button variant="primary" className="mt-3">
              Get Access
            </Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item> */}

      <Carousel.Item className={styles.item}>
        <Carousel.Caption className={styles.caption}>
          <h3>Coffee Shop</h3>

          <p>
            Have a coffee and some breakfast in our coffee shop downstairs. Busy
            working? We'll serve you to the desk so you won't miss a beat!
          </p>

          <Link to="#contact">
            <Button variant="primary" className="mt-3">
              Get Access
            </Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item>

      {/* <Carousel.Item className={styles.item}>
        <Carousel.Caption className={styles.caption}>
          <h3>Coffee Shop</h3>

          <p>
            Have a coffee and some breakfast in our coffee shop downstairs. Busy
            working? We'll serve you to the desk so you won't miss a beat!
          </p>

          <Link to="#contact">
            <Button variant="primary" className="mt-3">
              Get Access
            </Button>
          </Link>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  )
}

export default Hero
