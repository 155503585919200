import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Container from "../Container/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import * as styles from "./About.module.scss"

const About = () => {
  return (
    <Container>
      <Row id="about" className={styles.section}>
        <Col
          sm={12}
          md={6}
          className="d-flex align-items-center d-none d-md-block"
        >
          <StaticImage
            src="../../images/bukithub-about.jpg"
            quality={95}
            alt="Bukithub Coworking Rear View"
          />
        </Col>
        <Col sm={12} md={6}>
          <StaticImage
            src="../../images/bukithub-about.jpg"
            quality={95}
            formats={["auto"]}
            alt="Bukithub Coworking Rear View"
            className="mb-4 d-md-none"
          />

          <h3 className="fs-6">About Us</h3>

          <h2 className="text-tertiary">BukitHub. Your Office</h2>

          <p className="my-3">
            Welcome to your Office in Uluwatu! Offering the most dedicated work
            experience around, it's all about feeling right at home. It's not a
            coworking space, it's so much more than that! It's a base for many
            to come back to, a solid Hub to support you in your doing. It's
            BukitHub. <br />
            It all started many years ago when coworking space was rare in
            Uluwatu. Two young guys decided to make their own office to work
            from. Realising that sharing it with interesting and inspiring
            people is much better than working alone, they decided to open it up
            to more and more people, until it had eventually become a coworking
            space.
            <br /> And here is where your story starts: Join us, make
            connections or bring your part of inspiration to the office and join
            an ever-evolving community of like-minded people! This office is
            equally yours as it is ours and we are happy to be welcoming you!
            It's your Office in Bali!
          </p>

          {/* <p className="my-3">
            We built BukitHub to create a dedicated work space for people with
            the need of a proper office. Open for everybody wanting to achieve
            things, this space is focused at giving you the best work experience
            around.
          </p>

          <p className="my-3">
            Whatever it is, you do, BukitHub is where you’ll get it done. Meet
            other people with the same hands-on attitude, network and co-operate
            with other freelancers, companies, remote workers and artists.
          </p>

          <p className="my-3">
            Walk-in hours are 9:00 a.m. to 6:00 p.m. on Monday-Saturday. Our
            weekly/monthly members get 24/7 access. Contact us now to schedule
            your visit.
          </p> */}
        </Col>
      </Row>
    </Container>
  )
}

export default About
