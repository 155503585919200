import * as React from "react"
import axios from "axios"
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaFacebook,
  FaWhatsapp,
  FaInstagram,
  FaClock,
} from "react-icons/fa"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "../Button/Button"
import Container from "../Container/Container"

import * as styles from "./Contact.module.scss"

const Contact = () => {
  const [name, setName] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [submitStatus, setSubmitStatus] = React.useState("idle")

  const formSubmitHandler = async e => {
    e.preventDefault()

    setSubmitStatus("loading")

    try {
      const body = {
        text: `New Contact Message (Bukithub Coworking)!\n\nName: ${name}\nEmail: ${email}\nPhone: ${phone}\nMessage: ${message}`,
      }

      await axios.post(`${process.env.GATSBY_API_URL}`, body)

      setSubmitStatus("success")
    } catch (error) {
      const err =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      console.error(err)

      setSubmitStatus("failed")
    }
  }

  return (
    <Container>
      <Row id="contact" className={`${styles.row} g-0`}>
        <h2 className="text-center text-tertiary mb-5">Contact Us</h2>

        <Col xs={12} sm={6} className="mb-3 mb-sm-0 pe-sm-4">
          <div className="d-flex align-items-center">
            <FaPhone className="text-tertiary fs-5 me-3" />
            <a href="tel:+6281138104100">+62 811-3810-4100</a>
          </div>

          <div className="d-flex align-items-center mt-3">
            <FaEnvelope className="text-tertiary fs-5 me-3" />
            <a href="mailto:info@bukithub.com">info@bukithub.com</a>
          </div>

          <div className="d-flex align-items-center mt-3">
            <FaMapMarkerAlt className="text-tertiary fs-5 me-3" />
            <a
              href="https://goo.gl/maps/v2UYKTTCnc92XSTbA"
              target="__blank"
              rel="noreferrer"
            >
              Jl. Bali Cliff No.177AA, Ungasan.
            </a>
          </div>

          <div className="mt-3">
            <p className="me-3">Admin Hours:</p>

            <div className="d-flex align-items-center">
              <FaClock className="text-tertiary fs-5 me-3" />
              <p>Monday-Friday (9AM-6PM)</p>
            </div>
          </div>

          <div className="mt-3">
            <p className="me-3">Find Us: </p>

            <div className="d-flex gap-3">
              <a
                href="https://www.facebook.com/bukithub"
                target="__blank"
                rel="noreferrer"
              >
                <FaFacebook className="fs-4" />
              </a>

              <a
                href="https://www.instagram.com/bukithub/"
                target="__blank"
                rel="noreferrer"
              >
                <FaInstagram className="fs-4" />
              </a>

              <a
                href="https://wa.me/6281138104100/?text="
                target="__blank"
                rel="noreferrer"
              >
                <FaWhatsapp className="fs-4" />
              </a>
              <a
                href="https://forms.gle/AbtNDmCG3exSUnoS7"
                target="__blank"
                rel="noreferrer"
                className="ms-auto"
              >
                <Button>Give us feedback! </Button>
              </a>
            </div>
          </div>

          <iframe
            className="mt-3 w-100"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.6394754347166!2d115.1611087!3d-8.8198989!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd25b27c998bfe9%3A0x7b808eaf62256d75!2sBukitHub!5e0!3m2!1sid!2sid!4v1655199897374!5m2!1sid!2sid"
            width="300"
            height="300"
            title="bukithub"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Col>
        <Col xs={12} sm={6} className="form-wrapper mt-4 mt-sm-0">
          {(submitStatus === "idle" || submitStatus === "loading") && (
            <Form onSubmit={formSubmitHandler}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={name}
                  onChange={e => setName(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>

              <Form.Group controlId="phone" className="mt-3">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  type="text"
                  required
                />
              </Form.Group>

              <Form.Group controlId="email" className="mt-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type="email"
                  required
                />
              </Form.Group>

              <Form.Group controlId="message" className="mt-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  as="textarea"
                  required
                />
              </Form.Group>

              <Button
                className="mt-3"
                type="submit"
                disabled={
                  !name ||
                  !phone ||
                  !email ||
                  !message ||
                  submitStatus === "loading"
                }
              >
                {submitStatus === "loading" ? "Submitting..." : "Send"}
              </Button>
            </Form>
          )}

          {submitStatus === "success" && (
            <strong>Success sending message!</strong>
          )}

          {submitStatus === "failed" && (
            <strong>Failed sending message!</strong>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Contact
