import * as React from "react"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "../Button/Button"
import Container from "../Container/Container"
import Star from "../Star/Star"
import Dropdown from "react-bootstrap/Dropdown"

import * as styles from "./Pricing.module.scss"

const Pricing = () => {
  return (
    <Container className={styles.row}>
      <h2 id="pricing" className="text-center text-tertiary mb-4">
        Pricing
      </h2>

      <Row className="gap-5 gap-sm-0 g-3">
        <Col
          className={`${styles.col} justify-content-center justify-content-md-center`}
          xs={12}
          md={6}
          lg={4}
        >
          <Card className={styles.card}>
            <Card.Body>
              <Card.Title>Daily Pass</Card.Title>

              <Dropdown.Divider className="my-4" />

              <Card.Text>
                <strong className={styles.priceTag}>150K IDR</strong> <br />/
                day
              </Card.Text>
              <Link to="#contact">
                <Button variant="primary" className="w-100 mt-3">
                  Contact Us
                </Button>
              </Link>

              <Dropdown.Divider className="my-4" />

              {/* <ul className={styles.list}>
                <li>AC, Kitchen, Shared Meeting Room</li>
                <li>Flexible walk-in during business hours</li>
                <li>Print & Scan, Projector, etc. </li>
              </ul> */}
              <ul className={styles.list}>
                <li>Stay as long as you want</li>
                <li>Full access to the facility</li>
                <li>Easy check-in</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>

        <Col
          className={`${styles.col} justify-content-center justify-content-md-center`}
          xs={12}
          md={6}
          lg={4}
        >
          <Card className={styles.card}>
            <Star />

            <Card.Body>
              <Card.Title>Weekly Pass</Card.Title>

              <Dropdown.Divider className="my-4" />

              <Card.Text>
                <strong className={styles.priceTag}>600K IDR</strong> <br />/
                week
              </Card.Text>
              <Link to="#contact">
                <Button variant="primary" className="w-100 mt-3">
                  Contact Us
                </Button>
              </Link>

              <Dropdown.Divider className="my-4" />

              {/* <ul className={styles.list}>
                <li>AC, Kitchen, Shared Meeting Room</li>
                <li>Dedicated desk for your work</li>
                <li>Print & Scan, Projector, etc.</li>
              </ul> */}
              <ul className={styles.list}>
                <li>24/7 access card</li>
                <li>Meeting room (1h/day if available) </li>
                <li>Dedicated Desk for you</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>

        <Col
          className={`${styles.col} justify-content-center justify-content-md-center`}
          xs={12}
          md={12}
          lg={4}
        >
          <Card className={styles.card}>
            <Star />

            <Card.Body>
              <Card.Title>Monthly Pass</Card.Title>

              <Dropdown.Divider className="my-4" />

              <Card.Text>
                <strong className={styles.priceTag}>2 Mil IDR</strong> <br />/
                month
              </Card.Text>
              <Link to="#contact">
                <Button variant="primary" className="w-100 mt-3">
                  Contact Us
                </Button>
              </Link>

              <Dropdown.Divider className="my-4" />

              {/* <ul className={styles.list}>
                <li>AC, Kitchen, Shared Meeting Room</li>
                <li>Dedicated desk for your work</li>
                <li>Print & Scan, Projector, etc.</li>
              </ul> */}
              <ul className={styles.list}>
                <li>24/7 access card</li>
                <li>Meeting room (1h/day if available) </li>
                <li>Dedicated Desk for you</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <p className="mt-4 text-center">
        Do you need a private meeting room or a virtual office?
      </p>

      <Row className="mt-3 gap-5 gap-sm-0 g-3">
        <Col
          className={`${styles.col} justify-content-center justify-content-md-center`}
          xs={12}
          md={12}
          lg={6}
        >
          <Card className={styles.meeting}>
            <Card.Body>
              <Card.Title>Private Meeting Room</Card.Title>

              <Dropdown.Divider className="my-4" />

              <Card.Text className="d-flex flex-column flex-md-row  gap-2 gap-md-5 justify-content-center align-items-center justify-content-md-evenly">
                <div className={styles.priceTag}>
                  <strong>200K IDR</strong> - 1 Hr
                  <br />
                  <strong>1 Mil IDR</strong> - 1 D
                </div>
              </Card.Text>

              <Link to="#contact" className="d-flex justify-content-center">
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Link>
              <Dropdown.Divider className="my-4" />
              <ul className={styles.list}>
                <li>Up to 6 People</li>
                <li>Projector, Ext. screen, Lan connection</li>
                <li>Complete privacy</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>

        <Col
          className={`${styles.col} justify-content-center justify-content-md-center`}
          xs={12}
          md={12}
          lg={6}
        >
          <Card className={styles.meeting}>
            <Card.Body>
              <Card.Title>Virtual Office</Card.Title>

              <Dropdown.Divider className="my-4" />

              <Card.Text className="d-flex flex-column flex-md-row  gap-2 gap-md-5 justify-content-center align-items-center justify-content-md-evenly">
                <div className={styles.priceTag}>
                  <strong>7 Mil IDR</strong> - 1 Year
                </div>
              </Card.Text>

              <Link to="#contact" className="d-flex justify-content-center">
                <Button variant="primary" className="mt-3">
                  Contact Us
                </Button>
              </Link>
              <Dropdown.Divider className="my-4" />
              <ul className={styles.list}>
                <li>Mail forwarding</li>
                <li>Business address</li>
                <li>Legal documents</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <p className="w-50 text-center mx-auto mt-5">
        For special inquiries, please{" "}
        <Link to="/#contact" className="text-tertiary">
          contact us
        </Link>{" "}
        with your request!
      </p>
    </Container>
  )
}

export default Pricing
