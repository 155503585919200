import * as React from "react"
import Helmet from "react-helmet"
import Seo from "../components/Seo"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Hero from "../components/Hero/Hero"
import About from "../components/About/About"
import Contact from "../components/Contact/Contact"
import Pricing from "../components/Pricing/Pricing"
import Facility from "../components/Facility/Facility"

const IndexPage = () => {
  return (
    <>
    <Helmet script={[{
  type: 'text/javascript',
  innerHTML: `window.chatpilotConfig = Object.assign(window.chatpilotConfig || {
    chatbotId: "22f876ca96464f9e84328bd7cd3d279f",
    domain: "https://www.newoaks.ai"
  })` }]}/>
    <Helmet>
    <script
      src="https://www.newoaks.ai/embed.min.js"
      charset="utf-8"
      defer
    ></script>
      </Helmet>
      <Seo title="Co-Working and Office" />

      <Header isLanding />

      <Hero />

      <About />

      <Facility />

      <Pricing />

      <Contact />

      <Footer />


    </>
  )
}

export default IndexPage
