import * as React from "react"

import * as styles from "./Star.module.scss"

const Star = () => {
  return (
    <div className={styles.wrapper}>
      {/* <i className={styles.star}></i> */}
      <p>24/7 Access</p>
    </div>
  )
}

export default Star
