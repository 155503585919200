import * as React from "react"
import { FaCheckCircle } from "react-icons/fa"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import * as styles from "./Facility.module.scss"

const Facility = () => {
  return (
    <Row id="facilities" className="g-0">
      <Col xs={12} md={5} className={styles.image}></Col>
      <Col className={styles.facilityText} xs={12} md={7}>
        <h3 className="fs-6">Facilities</h3>

        {/* <h2>Why Choose Us?</h2> */}
        <h2>What Makes a Great Office?</h2>

        {/* <p className="my-3">
          Wherever you live on the Bukit, chances are you will not live further
          than 10 Minutes from Bukithub. It's a conveniently located space with
          plenty of shops and restaurants around, not more than 15 minutes from
          the international Airport and easily accessible even by car.
        </p> */}
        <p className="my-3">
          Uluwatu, Nusa Dua, Jimbaran, Ungasan, really any place you could live
          on the Bukit is probably not further than 10 minutes from BukitHub.
          It's a conveniently located space with plenty of shops and restaurants
          around, some 20 minutes from the airport and easily accessible car as
          well.
        </p>

        <Row className="g-4 mt-4">
          <Col xs={12} md={6} className={styles.benefitWrapper}>
            <div>
              <FaCheckCircle size={"3rem"} />
            </div>

            {/* <div>
              <h5>24/7 Access</h5>

              <p>
                Having an important call at 4am? No problem, we got you covered!
                All our members get an access card to enter any time they want.
              </p>
            </div> */}
            <div>
              <h5> Its All Here</h5>

              <p>
                Strong AC, hot shower, a full kitchen with tea and coffee,
                printer and scanner, 2k monitors, lockers, a meeting room, 3d
                printer and nice people.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} className={styles.benefitWrapper}>
            <div>
              <FaCheckCircle size={"3rem"} />
            </div>

            {/* <div>
              <h5>Comfortable Chair</h5>

              <p>
                You will be able to sit down and focus on your work for hours
                without feeling discomfort.
              </p>
            </div> */}
            <div>
              <h5>Its Your Office</h5>

              <p>
                Come and go as you please. Leave your sticky-note-mess on the
                desk for another day. Coming from a surf? Stack your board, take
                a shower and hustle along! It's up to you.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} className={styles.benefitWrapper}>
            <div>
              <FaCheckCircle size={"3rem"} />
            </div>

            {/* <div>
              <h5>All Included</h5>

              <p>
                Strong Ac, Bathroom with hot shower, a full kitchen with free
                Tea and Koffee, a printer/scanner and fast WiFi of course!
              </p>
            </div> */}
            <div>
              <h5>Here to Support</h5>

              <p>
                We went through it all and got the contacts and expertise you
                may search for. Talk to our friendly staff and we'll go beyond
                to help you out. Going home for the summer? We'll be here when
                you return, we got your back!
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} className={styles.benefitWrapper}>
            <div>
              <FaCheckCircle size={"3rem"} />
            </div>

            {/* <div>
              <h5>Perfect Workspace</h5>

              <p>
                A silent environment to really focus on work and not be
                distracted by other people or music.
              </p>
            </div> */}
            <div>
              <h5>Works Your Way</h5>

              <p>
                Working remote hours? Early morning calls to down under or late
                night work for Europe or the US? No problem, we got you covered!
                All our members get an access card to enter any time they want.
              </p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Facility
